// Vuetify
import { createVuetify } from 'vuetify'

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// <v-date-input
import { VDateInput } from 'vuetify/labs/VDateInput'

// locale for <v-date-input
import { en, pt } from 'vuetify/locale'

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

  locale: {
    locale: 'en',
    fallback: 'en',
    messages: { pt, en },
  },

  date: {
    locale: {
      en: 'pt',
    }
  },
  // theme: { defaultTheme: "light" },

  icons: {
    defaultSet: 'mdi', // This is already the default value - only for display purposes
  },

  components: {
    VDateInput, // <v-date-input
  },

})
