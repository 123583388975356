export function postalCodeList() {
  // CLDR 27.0.3
  const postalCode = [{
      'code': "AD",
      'reg': "AD\\d{3}"
    },
    {
      'code': "AM",
      'reg': "(37)?\\d{4}"
    },
    {
      'code': "AR",
      'reg': "([A-HJ-NP-Z])?\\d{4}([A-Z]{3})?"
    },
    {
      'code': "AS",
      'reg': "96799"
    },
    {
      'code': "AT",
      'reg': "\\d{4}"
    },
    {
      'code': "AU",
      'reg': "\\d{4}"
    },
    {
      'code': "AX",
      'reg': "22\\d{3}"
    },
    {
      'code': "AZ",
      'reg': "\\d{4}"
    },
    {
      'code': "BA",
      'reg': "\\d{5}"
    },
    {
      'code': "BB",
      'reg': "(BB\\d{5})?"
    },
    {
      'code': "BD",
      'reg': "\\d{4}"
    },
    {
      'code': "BE",
      'reg': "\\d{4}"
    },
    {
      'code': "BG",
      'reg': "\\d{4}"
    },
    {
      'code': "BH",
      'reg': "((1[0-2]|[2-9])\\d{2})?"
    },
    {
      'code': "BM",
      'reg': "[A-Z]{2}[ ]?[A-Z0-9]{2}"
    },
    {
      'code': "BN",
      'reg': "[A-Z]{2}[ ]?\\d{4}"
    },
    {
      'code': "BR",
      'reg': "\\d{5}[\\-]?\\d{3}"
    },
    {
      'code': "BY",
      'reg': "\\d{6}"
    },
    {
      'code': "CA",
      'reg': "[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ ]?\\d[ABCEGHJ-NPRSTV-Z]\\d"
    },
    {
      'code': "CC",
      'reg': "6799"
    },
    {
      'code': "CH",
      'reg': "\\d{4}"
    },
    {
      'code': "CK",
      'reg': "\\d{4}"
    },
    {
      'code': "CL",
      'reg': "\\d{7}"
    },
    {
      'code': "CN",
      'reg': "\\d{6}"
    },
    {
      'code': "CR",
      'reg': "\\d{4,5}|\\d{3}-\\d{4}"
    },
    {
      'code': "CS",
      'reg': "\\d{5}"
    },
    {
      'code': "CV",
      'reg': "\\d{4}"
    },
    {
      'code': "CX",
      'reg': "6798"
    },
    {
      'code': "CY",
      'reg': "\\d{4}"
    },
    {
      'code': "CZ",
      'reg': "\\d{3}[ ]?\\d{2}"
    },
    {
      'code': "DE",
      'reg': "\\d{5}"
    },
    {
      'code': "DK",
      'reg': "\\d{4}"
    },
    {
      'code': "DO",
      'reg': "\\d{5}"
    },
    {
      'code': "DZ",
      'reg': "\\d{5}"
    },
    {
      'code': "EC",
      'reg': "([A-Z]\\d{4}[A-Z]|(?:[A-Z]{2})?\\d{6})?"
    },
    {
      'code': "EE",
      'reg': "\\d{5}"
    },
    {
      'code': "EG",
      'reg': "\\d{5}"
    },
    {
      'code': "ES",
      'reg': "\\d{5}"
    },
    {
      'code': "ET",
      'reg': "\\d{4}"
    },
    {
      'code': "FI",
      'reg': "\\d{5}"
    },
    {
      'code': "FK",
      'reg': "FIQQ 1ZZ"
    },
    {
      'code': "FM",
      'reg': "(9694[1-4])([ \\-]\\d{4})?"
    },
    {
      'code': "FO",
      'reg': "\\d{3}"
    },
    {
      'code': "FR",
      'reg': "\\d{2}[ ]?\\d{3}"
    },
    {
      'code': "GB",
      'reg': "GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\\d{1,4}"
    },
    {
      'code': "GE",
      'reg': "\\d{4}"
    },
    {
      'code': "GF",
      'reg': "9[78]3\\d{2}"
    },
    {
      'code': "GG",
      'reg': "GY\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}"
    },
    {
      'code': "GL",
      'reg': "39\\d{2}"
    },
    {
      'code': "GN",
      'reg': "\\d{3}"
    },
    {
      'code': "GP",
      'reg': "9[78][01]\\d{2}"
    },
    {
      'code': "GR",
      'reg': "\\d{3}[ ]?\\d{2}"
    },
    {
      'code': "GS",
      'reg': "SIQQ 1ZZ"
    },
    {
      'code': "GT",
      'reg': "\\d{5}"
    },
    {
      'code': "GU",
      'reg': "969[123]\\d([ \\-]\\d{4})?"
    },
    {
      'code': "GW",
      'reg': "\\d{4}"
    },
    {
      'code': "HM",
      'reg': "\\d{4}"
    },
    {
      'code': "HN",
      'reg': "(?:\\d{5})?"
    },
    {
      'code': "HR",
      'reg': "\\d{5}"
    },
    {
      'code': "HT",
      'reg': "\\d{4}"
    },
    {
      'code': "HU",
      'reg': "\\d{4}"
    },
    {
      'code': "ID",
      'reg': "\\d{5}"
    },
    {
      'code': "IL",
      'reg': "\\d{5}"
    },
    {
      'code': "IM",
      'reg': "IM\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}"
    },
    {
      'code': "IN",
      'reg': "\\d{6}"
    },
    {
      'code': "IO",
      'reg': "BBND 1ZZ"
    },
    {
      'code': "IQ",
      'reg': "\\d{5}"
    },
    {
      'code': "IS",
      'reg': "\\d{3}"
    },
    {
      'code': "IT",
      'reg': "\\d{5}"
    },
    {
      'code': "JE",
      'reg': "JE\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}"
    },
    {
      'code': "JO",
      'reg': "\\d{5}"
    },
    {
      'code': "JP",
      'reg': "\\d{3}-\\d{4}"
    },
    {
      'code': "KE",
      'reg': "\\d{5}"
    },
    {
      'code': "KG",
      'reg': "\\d{6}"
    },
    {
      'code': "KH",
      'reg': "\\d{5}"
    },
    {
      'code': "KR",
      'reg': "\\d{3}[\\-]\\d{3}"
    },
    {
      'code': "KW",
      'reg': "\\d{5}"
    },
    {
      'code': "KZ",
      'reg': "\\d{6}"
    },
    {
      'code': "LA",
      'reg': "\\d{5}"
    },
    {
      'code': "LB",
      'reg': "(\\d{4}([ ]?\\d{4})?)?"
    },
    {
      'code': "LI",
      'reg': "(948[5-9])|(949[0-7])"
    },
    {
      'code': "LK",
      'reg': "\\d{5}"
    },
    {
      'code': "LR",
      'reg': "\\d{4}"
    },
    {
      'code': "LS",
      'reg': "\\d{3}"
    },
    {
      'code': "LT",
      'reg': "\\d{5}"
    },
    {
      'code': "LU",
      'reg': "\\d{4}"
    },
    {
      'code': "LV",
      'reg': "\\d{4}"
    },
    {
      'code': "MA",
      'reg': "\\d{5}"
    },
    {
      'code': "MC",
      'reg': "980\\d{2}"
    },
    {
      'code': "MD",
      'reg': "\\d{4}"
    },
    {
      'code': "ME",
      'reg': "8\\d{4}"
    },
    {
      'code': "MG",
      'reg': "\\d{3}"
    },
    {
      'code': "MH",
      'reg': "969[67]\\d([ \\-]\\d{4})?"
    },
    {
      'code': "MK",
      'reg': "\\d{4}"
    },
    {
      'code': "MN",
      'reg': "\\d{6}"
    },
    {
      'code': "MP",
      'reg': "9695[012]([ \\-]\\d{4})?"
    },
    {
      'code': "MQ",
      'reg': "9[78]2\\d{2}"
    },
    {
      'code': "MT",
      'reg': "[A-Z]{3}[ ]?\\d{2,4}"
    },
    {
      'code': "MU",
      'reg': "(\\d{3}[A-Z]{2}\\d{3})?"
    },
    {
      'code': "MV",
      'reg': "\\d{5}"
    },
    {
      'code': "MX",
      'reg': "\\d{5}"
    },
    {
      'code': "MY",
      'reg': "\\d{5}"
    },
    {
      'code': "NC",
      'reg': "988\\d{2}"
    },
    {
      'code': "NE",
      'reg': "\\d{4}"
    },
    {
      'code': "NF",
      'reg': "2899"
    },
    {
      'code': "NG",
      'reg': "(\\d{6})?"
    },
    {
      'code': "NI",
      'reg': "((\\d{4}-)?\\d{3}-\\d{3}(-\\d{1})?)?"
    },
    {
      'code': "NL",
      'reg': "\\d{4}[ ]?[A-Z]{2}"
    },
    {
      'code': "NO",
      'reg': "\\d{4}"
    },
    {
      'code': "NP",
      'reg': "\\d{5}"
    },
    {
      'code': "NZ",
      'reg': "\\d{4}"
    },
    {
      'code': "OM",
      'reg': "(PC )?\\d{3}"
    },
    {
      'code': "PF",
      'reg': "987\\d{2}"
    },
    {
      'code': "PG",
      'reg': "\\d{3}"
    },
    {
      'code': "PH",
      'reg': "\\d{4}"
    },
    {
      'code': "PK",
      'reg': "\\d{5}"
    },
    {
      'code': "PL",
      'reg': "\\d{2}-\\d{3}"
    },
    {
      'code': "PM",
      'reg': "9[78]5\\d{2}"
    },
    {
      'code': "PN",
      'reg': "PCRN 1ZZ"
    },
    {
      'code': "PR",
      'reg': "00[679]\\d{2}([ \\-]\\d{4})?"
    },
    {
      'code': "PT",
      'reg': "\\d{4}([\\-]\\d{3})?"
    },
    {
      'code': "PW",
      'reg': "96940"
    },
    {
      'code': "PY",
      'reg': "\\d{4}"
    },
    {
      'code': "RE",
      'reg': "9[78]4\\d{2}"
    },
    {
      'code': "RO",
      'reg': "\\d{6}"
    },
    {
      'code': "RS",
      'reg': "\\d{6}"
    },
    {
      'code': "RU",
      'reg': "\\d{6}"
    },
    {
      'code': "SA",
      'reg': "\\d{5}"
    },
    {
      'code': "SE",
      'reg': "\\d{3}[ ]?\\d{2}"
    },
    {
      'code': "SG",
      'reg': "\\d{6}"
    },
    {
      'code': "SH",
      'reg': "(ASCN|STHL) 1ZZ"
    },
    {
      'code': "SI",
      'reg': "\\d{4}"
    },
    {
      'code': "SJ",
      'reg': "\\d{4}"
    },
    {
      'code': "SK",
      'reg': "\\d{3}[ ]?\\d{2}"
    },
    {
      'code': "SM",
      'reg': "4789\\d"
    },
    {
      'code': "SN",
      'reg': "\\d{5}"
    },
    {
      'code': "SO",
      'reg': "\\d{5}"
    },
    {
      'code': "SZ",
      'reg': "[HLMS]\\d{3}"
    },
    {
      'code': "TC",
      'reg': "TKCA 1ZZ"
    },
    {
      'code': "TH",
      'reg': "\\d{5}"
    },
    {
      'code': "TJ",
      'reg': "\\d{6}"
    },
    {
      'code': "TM",
      'reg': "\\d{6}"
    },
    {
      'code': "TN",
      'reg': "\\d{4}"
    },
    {
      'code': "TR",
      'reg': "\\d{5}"
    },
    {
      'code': "TW",
      'reg': "\\d{3}(\\d{2})?"
    },
    {
      'code': "UA",
      'reg': "\\d{5}"
    },
    {
      'code': "US",
      'reg': "\\d{5}([ \\-]\\d{4})?"
    },
    {
      'code': "UY",
      'reg': "\\d{5}"
    },
    {
      'code': "UZ",
      'reg': "\\d{6}"
    },
    {
      'code': "VA",
      'reg': "00120"
    },
    {
      'code': "VE",
      'reg': "\\d{4}"
    },
    {
      'code': "VI",
      'reg': "008(([0-4]\\d)|(5[01]))([ \\-]\\d{4})?"
    },
    {
      'code': "WF",
      'reg': "986\\d{2}"
    },
    {
      'code': "XK",
      'reg': "\\d{5}"
    },
    {
      'code': "YT",
      'reg': "976\\d{2}"
    },
    {
      'code': "YU",
      'reg': "\\d{5}"
    },
    {
      'code': "ZA",
      'reg': "\\d{4}"
    },
    {
      'code': "ZM",
      'reg': "\\d{5}"
    }

  ];
  return postalCode;
};
// https://stackoverflow.com/questions/578406/what-is-the-ultimate-postal-code-and-zip-regex
