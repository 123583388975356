<template>
  <div>
    <!-- -->
    <v-divider class="mb-2 mt-3"></v-divider>
    <v-text-field
      color="primary"
      variant="underlined"
      :label="$t(labelPassword)"
      v-model="password.password"
      class="ml-10 mt-6 me-4"
      :hint="$t('login.At least 8 characters')"
      counter
      autocomplete="off"
      maxlength="30"
      :type="password.showPassword ? 'text' : 'password'"
      :append-inner-icon="password.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append-inner="password.showPassword = !password.showPassword"
      :rules="[rules.required, rules.min, rules.strength]"
      @keypress="checkQuote($event)"
      validate-on="blur"
    ></v-text-field>
    <v-card
      flat
      v-if="password.password"
      class="ml-10 mt-0 me-4"
    >
      <v-progress-linear
        :color="score.color"
        :model-value="score.value"
      ></v-progress-linear>
    </v-card>

    <!-- @keyup.enter="loginUser" -->
    <!-- :rules="[(v) => !!v || $t('rules.Required Field')]" -->

    <v-text-field
      color="primary"
      variant="underlined"
      :label="$t('user.Confirm Password *')"
      v-model="password.passwordConfirm"
      class="ml-10 mt-8 mb-6 me-4"
      id="passwordConfirm"
      name="passwordConfirm"
      :hint="$t('login.At least 8 characters')"
      counter
      autocomplete="off"
      maxlength="30"
      onpaste="return false"
      :type="password.showPasswordConfirm ? 'text' : 'password'"
      :append-inner-icon="password.showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append-inner="password.showPasswordConfirm = !password.showPasswordConfirm"
      @keypress="checkQuote($event)"
      :rules="[rules.required]"
    ></v-text-field>
  </div>
</template>

<script>
//
import zxcvbn from "zxcvbn";

export default {
  name: "Password",
  props: {
    password: {
      password: "",
      passwordConfirm: "",
      showPassword: false,
      showPasswordConfirm: false,
    },
  },
  data() {
    return {
      labelPassword: "user.Password *",
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///  RULES     //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      rules: {
        required: (value) => !!value || this.$t("rules.Required Field"),
        min: (v) =>
          v.length >= 8 ||
          this.$t("user.Use 8 characters or more for your password"),
        strength: (v) => zxcvbn(v).score >= 3 || this.$t("user.msgRule01"),
        //
      },
    };
  },
  computed: {
    // https://codepen.io/harashoo/pen/PoqKORg
    score() {
      const result = zxcvbn(this.password.password);
      //
      switch (result.score) {
        case 4:
          return {
            color: "green",
            value: 100,
          };
        case 3:
          return {
            color: "blue",
            value: 75,
          };
        case 2:
          return {
            color: "orange",
            value: 50,
          };
        case 1:
          return {
            color: "red",
            value: 25,
          };
        default:
          return {
            color: "red",
            value: 0,
          };
      }
    },
  },
  methods: {
    ///////////////
    checkQuote(e) {
      if (e.charCode == 34) e.preventDefault(); // double quotes
      if (e.charCode == 39) e.preventDefault(); // single quotes
    },
  },
  ///////////
  created() {
    // footer //
    // if (this.$route.params.pageId === "footer") {
    if (sessionStorage.pageOrigin === "footer") {
      this.labelPassword = "user.New Password *";
    }
    //
  },
};
</script>
