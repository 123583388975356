<template>
  <div>
    <nav>
      <!-- NAVBAR-->
      <Navbar :navbar="navbar"></Navbar>
    </nav>
    <!-- <main> -->
    <v-container fluid>
      <v-card
        flat
        class="mx-auto"
        max-width="660"
      >
        <v-row class="ml-4 mt-6 mb-2">
          <v-col cols="6">
            <v-row>
              <v-img
                src="@/assets/logo/logo_blue21.png"
                cover
                max-width="240"
                max-height="60"
              ></v-img>
            </v-row>
          </v-col>
        </v-row>

        <v-col cols="12">
          <h1 class="text-h5 titleH1">
            {{ $t("common." + $route.meta.title) }}
          </h1>
        </v-col>

        <v-alert
          style="line-height: 1.5"
          type="info"
          border="start"
          variant="tonal"
          transition="slide-y-reverse-transition"
          closable
        >{{ $t("login.titleHelpForgotMyPassword") }}</v-alert>

        <!-- FORM -->
        <v-form
          v-model="formValid"
          ref="form"
        >
          <div>
            <v-col cols="12">
              <!-- "e-mail" -->
              <v-text-field
                color="primary"
                variant="underlined"
                :label="$t('user.email *')"
                v-model="email"
                id="email"
                name="email"
                type="email"
                maxlength="99"
                append-icon="mdi-email-outline"
                @keyup.enter="forgotMyPassword()"
                :rules="[rules.emailRules()]"
                validate-on="blur"
              ></v-text-field>
            </v-col>

            <!--  -->
            <v-card
              class="mt-4 mb-6"
              flat
            >
              <v-col cols="12">
                <v-btn
                  block
                  variant="outlined"
                  color="primary"
                  :disabled="btnSendDisabled"
                  @click.prevent="forgotMyPassword()"
                >
                  <div>
                    <!-- <v-icon left>mdi-lock-open-outline</v-icon> -->
                    {{ $t("buttom.Send") }}
                  </div>
                </v-btn>
              </v-col>
            </v-card>

            <!-- snackbar -->
            <v-snackbar
              v-model="snackbar.show"
              location="top"
              :color="snackbar.color"
              :timeout="snackbar.timeout"
            >{{ snackbar.text }}</v-snackbar>
          </div>
        </v-form>
      </v-card>
    </v-container>
    <!-- </main> -->
  </div>
</template>
<script>
//
// import i18n from "@/i18n.js";
//
import Navbar from "@/app/2_common/components/page/Navbar.vue";
import { api } from "@/services.js";

export default {
  name: "ForgotMyPassword",
  components: {
    Navbar,
    //
  },
  data() {
    return {
      dialog: false,
      //
      email: "",
      locale: "",
      //
      // standard
      formValid: true,
      btnSendDisabled: false,
      //
      titleHelpForgotMyPassword: "",
      // Props Navbar
      navbar: {
        menu: [
          {
            text: "Help",
            icon: "mdi-help-circle-outline",
            action: "",
            route: "",
          },
        ],
      },
      // snackbar
      snackbar: {
        show: false,
        color: "",
        text: "",
        timeout: 3000,
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///  RULES     //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      rules: {
        emailRules: (value) => {
          let email = this.email.toLowerCase();
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!pattern.test(email)) return this.$t("rules.Invalid email");

          if (email.indexOf("'") > 0) return this.$t("rules.Invalid email");

          this.email = email;
          return true;
        },
      },
    };
  },
  methods: {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  JS     /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  ROUTER  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    goBack() {
      this.snackbar.show = false;
      this.$router.push({
        name: "login",
      });
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  STORE  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// MESSAGE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    snackbarTimeout(timeout) {
      // this.mainButton.btnSaveDisabled = false;
      this.snackbar.show = true;
      this.snackbar.timeout = timeout;
      setTimeout(() => {
        this.goBack();
      }, this.snackbar.timeout);
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  API  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // //
    async forgotMyPassword() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) {
        // snackbar
        window.scrollTo(0, 0);
        this.snackbar.color = "warning";
        window.scrollTo(0, 0);
        this.snackbar.text = this.$t("message.alertTyping");
        this.snackbar.show = true;
        //
        return;
      }
      this.btnSendDisabled = true;
      let auth = {
        email: this.email,
        locale: this.locale,
      };
      api
        .post(`/login/forgotMyPassword/`, auth)
        .then((response) => {
          // console.log(response.data);
          // snackbar
          if (response.data) window.scrollTo(0, 0);
          this.snackbar.color = "success";
          this.snackbar.text =
            this.$t("login.An e-mail was sent to ") +
            this.email +
            " " +
            this.$t("login.with instructions");
          this.snackbarTimeout(10000);
          //
        })
        .catch((err) => {
          this.btnSendDisabled = false;
          // snackbar
          window.scrollTo(0, 0);
          this.snackbar.color = "error";
          this.snackbar.text = err + " => forgotMyPassword";
          this.snackbar.show = true;
          //
        });
    },
  },
  //
  created() {
    if (localStorage.locale != undefined) {
      this.$i18n.locale = localStorage.locale;
    } else {
      this.$i18n.locale = window.navigator.language.toLowerCase();
    }
    //
    document.title = "BLUE21 ";

    // this.email = this.$route.params.email;
    this.email = sessionStorage.email || "";
    this.locale = localStorage.locale;
    //
    localStorage.clear(); // old junk cleaner
  },
};
</script>

