<template>
  <div>
    <v-divider
      v-if="!mainButton.btnNewItem"
      class="mt-4"
    ></v-divider>
    <v-row
      class="mt-4"
      align="center"
      justify="center"
    >
      <!-- Save, Reorder, New Item -->
      <v-col
        :cols="5"
        md="3"
        sm="5"
        xs="5"
        class="text-center"
      >
        <v-btn
          block
          variant="outlined"
          class="ml-2 me-2 mt-2"
          color="primary"
          :disabled="mainButton.btnSaveDisabled"
          @click="$emit('save')"
        >
          <div v-if="!mainButton.btnReorder && !mainButton.btnNewItem">
            <v-icon left>mdi-pencil</v-icon>
            <!-- Save -->
            {{ $t("buttom.Save") }}
          </div>
          <div v-if="mainButton.btnReorder">
            <v-icon left>mdi-arrow-all</v-icon>
            <!-- Reorder -->
            {{ $t("buttom.Reorder") }}
          </div>
          <div v-if="mainButton.btnNewItem">
            <v-icon left>mdi-plus</v-icon>
            <!-- New -->
            {{ $t("buttom.New Item") }}
          </div>
        </v-btn>
      </v-col>

      <!-- cancel -->
      <v-tooltip
        location="right"
        :text="$t('buttom.Cancel')"
        v-if="mainButton.btnCancelShow"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            variant="text"
            color="primary"
            icon
            class="ml-0 mt-2"
            @click="$emit('cancel')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </v-row>

    <v-row
      no-gutters
      align="center"
      justify="center"
    >
      <v-col
        :cols="5"
        md="3"
        sm="5"
        xs="5"
        class="text-center"
      >
        <v-progress-linear
          v-if="mainButton.btnSaveDisabled"
          indeterminate
          class="ml-2 me-2 mt-1"
          color="primary"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <br>
  </div>
</template>

<script>
export default {
  name: "MainButton",
  props: {
    mainButton: {},
  },
  methods: {},
};
</script>
