<template>
  <v-row justify="center">
    <div
      class="v-menu__content--active"
      style="display: none; z-index: 1000"
    >
      <v-dialog
        persistent
        v-model="dialog.show"
        max-width="600"
      > <!-- :value="value" -->
        <v-card>
          <v-card-title class="text-h5 text-wrap">
            <div v-if="!dialog.proceed">
              {{$t("dialog." + dialog.title)}}
            </div>
            <div v-if="dialog.proceed">
              {{ dialog.title }}
            </div>
          </v-card-title>
          <v-card-text class="text-subtitle-1 font-weight-black text-primary">{{
            dialog.text
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="
                dialog.show = false;
                $emit('cancel');
              "
            >{{ $t("buttom.Cancel") }}</v-btn>
            <v-btn
              v-if="!dialog.proceed"
              color="primary"
              @click="$emit('confirm')"
            >{{
              $t("buttom.Confirm")
            }}</v-btn>
            <v-btn
              v-if="dialog.proceed"
              color="primary"
              @click="$emit('proceed')"
            >{{
              $t("buttom.Proceed")
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-row>
</template>

<script>
export default {
  name: "Dialog",
  props: {
    dialog: {},
  },
  methods: {},
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
