import axios from 'axios'

let baseURL = ''
if (window.location.href.toLowerCase().includes('localhost')) {
  baseURL = 'http://localhost:3000'
} else if (window.location.href.toLowerCase().includes('teste.blue21.com.br')) {
  baseURL = 'https://teste.blue21.com.br:21500' // teste
} else {
  baseURL = 'https://app.blue21.com.br:21400' // prod
}
// alert("baseURL = " + baseURL);
const axiosInstance = axios.create({
  baseURL: baseURL
})
//
axiosInstance.interceptors.request.use(
  function (config) {
    // const token = 'Bearer ' + localStorage.section
    const token = 'Bearer ' + sessionStorage.section

    if (token) {
      config.headers.Authorization = token
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
//
export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint)
  },
  post(endpoint, body) {
    return axiosInstance.post(endpoint, body)
  },
  put(endpoint, body) {
    return axiosInstance.put(endpoint, body)
  },
  delete(endpoint) {
    return axiosInstance.delete(endpoint)
  }
}
// 
/// ////////////////////////////////////////////////////////////////////////////////
/// CEP
export function getCep(cep) {
  return axios.get(`https://viacep.com.br/ws/${cep}/json/`)
};

/// IP & city
export function getIp() {
  return axios.get(`http://ip-api.com/json`)
};

/// IP only -> https
export function getIpify() {
  return axios.get('https://api.ipify.org?format=json')
};

/// /////////////////////////////////////////////////////////////////////////////////
/// ///// API ReceitaWS
// cnpj
export function consultaCNPJ(cnpj) {
  // Consulta o CNPJ na ReceitaWS com 60 segundos de tempo limite
  return jsonp('https://www.receitaws.com.br/v1/cnpj/' + encodeURI(cnpj), 60000)
    .then((json) => {
      if (json.status === 'ERROR') {
        return Promise.reject(json.message)
      } else {
        return Promise.resolve(json)
      }
    })
}
/**
 * Implementação da requisição na web
 */
function jsonp(url, timeout) {
  // Gera um nome aleatório para a função de callback
  const func = 'jsonp_' + Math.random().toString(36).substring(2, 5)

  return new Promise(function (resolve, reject) {
    // Cria um script
    const script = document.createElement('script')

    // Cria um timer para controlar o tempo limite
    const timer = setTimeout(() => {
      reject('Tempo limite atingido')
      document.body.removeChild(script)
    }, timeout)

    // Cria a função de callback
    window[func] = (json) => {
      clearTimeout(timer)
      resolve(json)
      document.body.removeChild(script)
      delete window[func]
    }

    // Adiciona o script na página para inicializar a solicitação
    script.src = url + '?callback=' + encodeURI(func)
    document.body.appendChild(script)
  })
}
/// /////////////////////////////////////////////////////////////////////////////////
