<template>
  <div>
    <nav>
      <!-- NAVBAR-->
      <Navbar
        :navbar="navbar"
        @help="help"
      ></Navbar>
    </nav>
    <!-- <main> -->
    <v-container fluid>
      <v-card
        flat
        class="mx-auto"
        max-width="700"
      >
        <v-row class="ml-4 mt-2 mb-0">
          <v-col cols="6">
            <v-row>
              <v-img
                src="@/assets/logo/logo_blue21.png"
                cover
                max-width="280"
                max-height="70"
              ></v-img>
            </v-row>
          </v-col>
        </v-row>
        <v-col cols="12">
          <h1
            v-if="!pageOrigin"
            class="text-h5 titleH1"
          >{{ $t("login.title") }} </h1>
          <h1
            v-if="pageOrigin"
            class="text-h5 titleH1"
          > {{ $t("login.title") }} - {{ $t("common." + pageOrigin) }} {{ tradingName }}</h1>
        </v-col>
        <!-- environment = {{environment}} -->
        <div
          class="mt-2 mb-6"
          v-if="environment.includes('teste.blue21.com.br')"
        >
          <v-alert
            style="line-height: 1.5"
            type="warning"
            prominent
            closable
            lass="font-weight-medium"
          >
            {{ $t("login.environmentTesting") }}
          </v-alert>
        </div>

        <!-- this.$vuetify.locale = {{ this.$vuetify.locale }} -->
        <v-row dense>
          <v-col cols="4"></v-col>
          <v-col cols="8">
            <v-row
              align="end"
              justify="end"
            >
              <v-checkbox
                color="primary"
                :label="$t('login.Choose your language')"
                v-model="showLanguage"
                class="mt-0 mb-0 me-8"
              ></v-checkbox>
            </v-row>
            <!-- languages = {{ languages}}
            $i18n.locale = {{ $i18n.locale}} -->

            <!-- v-model="indexLanguage" -->
            <v-card
              v-if="showLanguage"
              class="mt-0 me-4 mb-4"
            >
              <v-list color="primary">
                <v-list-subheader>{{ $t("login.Language") }}</v-list-subheader>
                <v-divider></v-divider>
                <div>
                  <v-list-item
                    v-for="item in languages"
                    :key="item.locale"
                    :active="item.locale === $i18n.locale"
                    @click="changeLocale(item)"
                    :title="item.language"
                  >
                    <template v-slot:prepend>
                      <v-img class="me-2">
                        <flag
                          :iso="item.flag"
                          v-bind:squared="false"
                        />
                      </v-img>
                    </template>
                  </v-list-item>
                </div>
              </v-list>
            </v-card>
          </v-col>
        </v-row>

        <!-- FORM -->
        <v-form
          v-model="formValid"
          ref="form"
        >
          <v-divider class="mt-0 mb-4"></v-divider>

          <div>
            <v-col cols="12">
              <!-- "e-mail" -->
              <v-text-field
                color="primary"
                variant="underlined"
                :label="$t('user.email *')"
                v-model="email"
                class="ml-2 me-2"
                id="email"
                name="email"
                type="email"
                maxlength="99"
                append-inner-icon="mdi-email-outline"
                @keyup.enter="loginUser()"
                :rules="[rules.emailRules()]"
                validate-on="blur"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                color="primary"
                variant="underlined"
                :label="$t('user.Password *')"
                v-model="password"
                class="ml-2 me-2"
                id="password"
                name="password"
                autocomplete="on"
                maxlength="30"
                :type="showPassword ? 'text' : 'password'"
                :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :hint="$t('login.At least 8 characters')"
                counter
                @click:append-inner="showPassword = !showPassword"
                @keypress="checkQuote($event)"
                @keyup.enter="loginUser()"
                :rules="[(v) => !!v || $t('rules.Required Field')]"
              ></v-text-field>
            </v-col>

            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                variant="text"
                small
                class="me-5 mt-3"
                color="primary"
                @click="goForgotMyPassword()"
              >{{ $t("login.Forgot my Password") }}</v-btn>
            </v-row>

            <!-- Entry -->
            <v-card
              class="ml-2 mt-4 me-2"
              flat
            >
              <v-col cols="12">
                <v-btn
                  block
                  variant="outlined"
                  color="primary"
                  :disabled="btnEnterDisabled"
                  @click.prevent="loginUser()"
                >
                  <div>
                    <v-icon left>mdi-lock-open-outline</v-icon>
                    <!-- save -->
                    {{ $t("login.Enter") }}
                  </div>
                </v-btn>
              </v-col>
              <v-card
                flat
                v-if="btnEnterDisabled"
                class="ml-2 mt-0 me-2"
              >
                <v-progress-linear
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </v-card>
              <!-- Keep Connected
              <v-row>
                <v-spacer></v-spacer>
                <v-switch :label="$t('login.Keep Connected')" class="me-6 mt-4"></v-switch>
              </v-row>-->
            </v-card>

            <v-divider class="mt-4"></v-divider>

            <v-row>
              <v-btn
                variant="text"
                small
                class="ml-6 mt-8"
                color="primary"
                @click="goUrlHelp()"
              >
                <v-icon size="22px">mdi-help-circle-outline</v-icon>
                &nbsp;&nbsp;{{ $t("help.Help Site") }}
              </v-btn>
            </v-row>
            <br>
            <br>

            <div v-if="counterAccount">
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
            </div>

            <v-card
              v-if="pageOrigin != 'Deliver' && packageJson.name != 'blue21-app-delivery'"
              class="ml-2 mt-12"
              flat
            >
              <v-col cols="12">
                <v-btn
                  block
                  variant="outlined"
                  color="primary"
                  @click="goNewAccount()"
                >
                  <v-icon
                    class="me-4"
                    size="22px"
                  >mdi-account-outline</v-icon>
                  {{ $t("login.Create New Account") }}
                </v-btn>
              </v-col>
            </v-card>

            <!-- snackbar -->
            <v-snackbar
              v-model="snackbar.show"
              location="top"
              :color="snackbar.color"
              :timeout="snackbar.timeout"
            >{{ snackbar.text }}</v-snackbar>
          </div>
          <div class="ml-5 mt-2 mb-2">
            <small
              v-if="pageOrigin != 'Deliver'"
              style="color: #24b47e"
              id="copyright"
              class="text-body-2"
            >Copyright&copy; {{ packageJson.description }} - v{{ packageJson.version }}</small>
          </div>
        </v-form>

        <!-- /////////////////////////  ENTERPRISES  & ADDRESS /////////////////////////////////////////////////////////////////////////// -->
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            persistent
            max-width="660"
          >
            <v-toolbar color="cyan">
              <v-toolbar-title class="text-white">
                <div v-if="!informUserAddress">
                  {{ $t("enterprise.Select a Company") }}
                </div>
                <div v-if="informUserAddress">
                  {{ $t("deliver.Enter your Delivery Address :") }}
                </div>
              </v-toolbar-title>
            </v-toolbar>

            <v-card>
              <v-list
                lines="two"
                v-if="enterprises.length > 0"
              >
                <div v-if="!informUserAddress">
                  <v-list-item
                    color="primary"
                    v-for="item in enterprises"
                    :key="item.groupId.toString() + item.companyId.toString() "
                    @click.prevent="loginEnterprise(item)"
                    :title="item.companyName"
                  >
                    <template v-slot:prepend>
                      <v-avatar
                        size="48"
                        tile
                      >
                        <v-img
                          v-if="item.src"
                          :src="item.src"
                        ></v-img>
                      </v-avatar>
                    </template>
                    <v-list-item-subtitle
                      v-if="item.profile"
                      class="text-primary"
                    > {{ $t("common." + item.profile)}} &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;{{' GR' +  item.groupId.toString().padStart(2, "0")  + '-EN' + item.companyId.toString().padStart(2, "0")}}
                      <div v-if="item.customerId">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{'CL' + item.customerId.toString().padStart(2, "0")}}
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-divider></v-divider>
                </div>
              </v-list>

              <!-- ADDRESS -->
              <v-card
                flat
                v-if="informUserAddress"
                class="mt-3"
              >
                <span class="ml-10 font-weight-medium"> {{ this.companyName}}</span>
                <Address
                  :address="address"
                  ref="myAddress"
                ></Address>
              </v-card>

              <!-- <v-divider inset></v-divider> -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="informUserAddress && address.address"
                  variant="text"
                  color="primary"
                  class="me-4"
                  @click="saveAddress()"
                  :disabled="btnEnterDisabled"
                >
                  <v-icon size="16px">mdi-pencil</v-icon>
                  {{ $t("buttom.Save") }}
                </v-btn>

                <v-btn
                  variant="text"
                  color="primary"
                  class="ml-4 me-4"
                  @click="dialog = false; clearAddrees()"
                >

                  {{$t("buttom.Cancel")}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <br />

      </v-card>
    </v-container>
    <!-- </main> -->
  </div>
</template>

<script>
// import i18n from "@/i18n.js";
//
import Address from "@/app/3_company/components/entity/Address.vue";
import Navbar from "@/app/2_common/components/page/Navbar.vue";
//
import { helpLink } from "@/helpers/helpers.js";

import { api } from "@/services.js";
import { getIp } from "@/services.js";
import { getIpify } from "@/services.js";

var platform = require("platform");
var packageJson = require("../../../../../package.json");

export default {
  name: "Login",
  components: {
    Address,
    Navbar,
    //
  },
  data() {
    return {
      environment: "",
      tradingName: "",
      companyName: "",
      informUserAddress: false,
      pageOrigin: "", // Deliver
      groupIdOrigin: "",
      companyIdOrigin: "",
      counterAccount: "",
      //
      enterprises: [],
      dialog: false,
      //
      email: "",
      password: "",
      groupId: 0,
      companyId: 0,
      //
      showPassword: false,
      //
      delay: 100,
      // standard
      formValid: true,
      btnEnterDisabled: false,
      //
      showLanguage: false,
      indexLanguage: -1,
      browserLocale: "",
      //
      packageJson: packageJson,

      userInfo: {
        userIp: "",
        countryCode: "",
        country: "",
        region: "",
        regionName: "",
        city: "",
        latitudeIp: "",
        longitudeIp: "",
        navigator: "",
        device: "",
        operationalSystem: "",
        appVersion: "",
      },

      languages: [
        // { flag: "in", locale: "bn", language: "Bengali" },
        // { flag: "cn", locale: "zh", language: "Chinese" }, // ok
        { flag: "us", locale: "en-us", language: "English" },
        // { flag: "fr", locale: "fr", language: "French" },
        // { flag: "de", locale: "de", language: "German" },
        // { flag: "in", locale: "hi", language: "Hindi" },
        // { flag: "it", locale: "it", language: "Italian" },
        // { flag: "jp", locale: "ja", language: "Japanese" },
        // { flag: "pt", locale: "pt", language: "Portuguese" },
        { flag: "br", locale: "pt-br", language: "Portuguese Brazil" },
        // { flag: "in", locale: "pa", language: "Punjabi" },
        // { flag: "ru", locale: "ru", language: "Russian" }, // ok
        // { flag: "es", locale: "es", language: "Spanish" },
        // { flag: "ar", locale: "es-ar", language: "Spanish Argentina" },
      ],

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///  PROPS     //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      // Props Address
      address: {
        entityId: "",
        cepConsult: "",
        addressId: 0,
        foreignerAd: false,
        countryCode: "",
        address: "",
        number: "",
        complement: "",
        neighborhood: "",
        city: "",
        state: "",
        postalCode: "",
        //
        addressType: "",
        addressTypeIcon: "",
        reference: "",
        region: "",
        latitude: 0,
        longitude: 0,
        distance: 0,
        duration: 0,
        processDistance: false,
        //
        observationAd: "",
        inactiveAd: false,
        orderAd: "",
        update: false,
        //
        formValid: true,
      },

      // Props Navbar
      navbar: {
        menu: [
          {
            text: "Help",
            icon: "mdi-help-circle-outline",
            action: "help",
            route: "",
          },
        ],
      },
      // snackbar
      snackbar: {
        show: false,
        color: "",
        text: "",
        timeout: 3000,
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      ///  RULES     //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      rules: {
        emailRules: (value) => {
          let email = this.email.toLowerCase();
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!pattern.test(email)) return this.$t("rules.Invalid email");

          if (email.indexOf("'") > 0) return this.$t("rules.Invalid email");

          this.email = email;
          return true;
        },
      },
    };
  },
  methods: {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  JS     /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    changeLocale(item) {
      // console.log("item.locale = " + item.locale);
      let locale = item.locale;
      if (!locale) {
        locale = window.navigator.language.toLowerCase();
      }
      if (locale === "pt") locale = "pt-br";
      if (locale === "es-ar") locale = "es";

      // i18n.locale = locale;

      // vue3
      this.$i18n.locale = locale;
      this.$root.$i18n.locale = locale;
      // console.log("i18n.locale = " + i18n.locale);

      // locale vuetify v3
      // this.$vuetify.locale.current = locale;

      // console.log(" item = " + JSON.stringify(item));
      sessionStorage.locale = item.locale;
      // console.log("1 sessionStorage.locale = " + sessionStorage.locale);

      localStorage.locale = item.locale; // New Account Activation
      this.browserLocale = item.locale; // back-end
      localStorage.language = item.language;
      this.showLanguage = false;
    },
    //////////////////////
    goForgotMyPassword() {
      //
      sessionStorage.email = this.email;
      this.$router.push({
        name: "forgotMyPassword",
        // params: {
        //   email: this.email,
        // },
      });
    },

    /////////////
    goUrlHelp() {
      let link = "https://ajuda.blue21.com.br/";
      window.open(link, "_blank");
    },

    //////////////
    goNewAccount() {
      this.$store.commit("UPDATE_NEW_ACCOUNT", true);
      //
      sessionStorage.userId = "";
      sessionStorage.pageOrigin = "login";
      this.$router.push({
        name: "userNewAccount",
        // params: {
        //   userId: "",
        //   pageId: "login",
        // },
      });
    },

    ////////////////////////
    loginEnterprise(item) {
      // console.log(" item = " + JSON.stringify(item));
      //
      this.groupId = item.groupId;
      this.companyId = item.companyId;
      //
      if (item.profile == "Deliver" && !item.addressId) {
        this.companyName = item.companyName;
        // used in user_address_distance
        this.groupIdOrigin = item.groupId;
        this.companyIdOrigin = item.companyId;
        //
        this.address.countryCode = item.countryCode;
        this.address.latitudeOrigin = item.companyLat;
        this.address.longitudeOrigin = item.companyLng;
        this.informUserAddress = true;
        return;
      }
      // console.log(" this.enterprises = " + JSON.stringify(this.enterprises));
      this.$store.commit("UPDATE_ENTERPRISES", this.enterprises);
      //
      this.loginUser();
    },

    //////////////
    async saveAddress() {
      const { valid } = await this.$refs.form.validate();

      if (!valid || !this.address.formValid) {
        // snackbar
        window.scrollTo(0, 0);
        this.snackbar.color = "warning";
        window.scrollTo(0, 0);
        this.snackbar.text = this.$t("message.alertTyping");
        this.snackbar.show = true;
        return;
      }

      // keep this console.info forever save apiGeocoder
      console.info("apiGeocoder=", sessionStorage.getItem("apiGeocoder"));
      // apiGeocoder ////////////////////////////////////////////////////
      if (!sessionStorage.getItem("apiGeocoder")) {
        let delay = await this.later(1500); // waits for the api google
      }
      this.address.latitude = 0;
      this.address.longitude = 0;
      this.address.distance = 0;
      this.address.duration = 0;
      if (sessionStorage.getItem("apiGeocoder")) {
        let geocoder = JSON.parse(sessionStorage.getItem("apiGeocoder"));
        this.address.latitude = geocoder.destination.lat;
        this.address.longitude = geocoder.destination.lng;
        //
        if (geocoder.distance && geocoder.distance.value) {
          this.address.processDistance = true;
          this.address.distance = geocoder.distance.value || 0;
          this.address.duration = geocoder.duration.value || 0;
        }
        // console.log("this.address = ", JSON.stringify(this.address));
        // sessionStorage.removeItem("apiGeocoder");
      } else {
        window.scrollTo(0, 0);
        this.snackbar.color = "warning";
        window.scrollTo(0, 0);
        this.snackbar.text = this.$t(
          "message.It was not possible to obtain the geolocation of this address"
        );
        this.snackbar.show = true;
        this.mainButton.btnSaveDisabled = false;
        return;
      }

      this.$store.commit("UPDATE_ENTERPRISES", this.enterprises);
      //
      this.loginUser();
    },

    ////////////////////
    async later(delay) {
      this.delay = this.delay * 2;
      return new Promise(function (resolve) {
        // console.log(" 2- delay = " + delay);
        setTimeout(resolve, delay);
      });
    },
    ///////////////
    checkQuote(e) {
      if (e.charCode == 34) e.preventDefault(); // double quotes
      if (e.charCode == 39) e.preventDefault(); // single quotes
    },

    ////////////////
    clearAddrees() {
      this.informUserAddress = false;
      this.groupId = "";
      this.companyId = "";

      //
      this.address.cepConsult = "";
      this.address.address = "";
      this.address.number = "";
      this.address.complement = "";
      this.address.neighborhood = "";
      this.address.city = "";
      this.address.state = "";
      this.address.postalCode = "";
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  ROUTER  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////
    help() {
      // console.log("this.$t = " + this.$t);
      let title = "Login Blue21";
      helpLink(this.$t, title, "", "");
    },
    //
    goBack() {
      this.snackbar.show = false;
      sessionStorage.email = this.email;
      this.$router.push({
        name: "userChangePassword",
        // params: { pageId: "Login", email: this.email },
      });
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  STORE  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// MESSAGE //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    snackbarTimeout(timeout) {
      this.snackbar.show = true;
      this.snackbar.timeout = timeout;
      setTimeout(() => {
        this.$router.push({ name: "menu" });
      }, this.snackbar.timeout);
    },
    ///////////////
    getUserInfo() {
      //
      if (window.location.href.toLowerCase().includes("localhost")) {
        // paid service 13 Euros per month
        getIp().then((response) => {
          // console.log(response);
          if (!response.data.erro) {
            this.userInfo.userIp = response.data.query;
            this.userInfo.country = response.data.country;
            this.userInfo.countryCode = response.data.countryCode;
            this.userInfo.region = response.data.region;
            this.userInfo.regionName = response.data.regionName;
            this.userInfo.city = response.data.city;
            this.userInfo.latitudeIp = response.data.lat;
            this.userInfo.longitudeIp = response.data.lon;
            // console.log(this.userInfo);
          } else {
            console.info(
              "Login: It was not possible to obtain the data from your IP"
            );
          }
        });
        // org -> only ip whith https
      } else {
        getIpify().then((response) => {
          // console.log(response);
          this.userInfo.userIp = response.data.ip;
        });
      }
      // platform.js
      this.userInfo.navigator = platform.name + " " + platform.version;
      if (platform.product) this.userInfo.device = platform.product; // ipad, sansung ..
      this.userInfo.operationalSystem =
        platform.os.family + " " + platform.os.version;
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///  API  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async loginUser() {
      //
      const { valid } = await this.$refs.form.validate();
      if (!valid) {
        // snackbar
        window.scrollTo(0, 0);
        this.snackbar.color = "warning";
        window.scrollTo(0, 0);
        this.snackbar.text = this.$t("message.alertTyping");
        this.snackbar.show = true;
        //
        return;
      }
      this.btnEnterDisabled = true;
      // setTimeout
      let delay = await this.later(this.delay);

      // console.log("groupIdOrigin : " + this.groupIdOrigin);
      let auth = {
        email: this.email,
        password: this.password,
        groupId: this.groupId,
        companyId: this.companyId,
        browserLocale: this.browserLocale,
        changePassword: false,
        //
        pageOrigin: this.pageOrigin,
        groupIdOrigin: this.groupIdOrigin,
        companyIdOrigin: this.companyIdOrigin,
        //
        appName: packageJson.name,
        userInfo: this.userInfo,
      };
      if (this.address.address) {
        auth.address = this.address;
      }
      // console.log("auth : " + JSON.stringify(auth));
      //
      api
        .post(`/login/`, auth)
        .then((response) => {
          // console.log(response.data);
          this.enterprises = response.data;
          // console.log("this.enterprises : " + JSON.stringify(this.enterprises));
          if (this.enterprises.length > 1) {
            this.btnEnterDisabled = false;
            this.dialog = true;
            return;
          }
          //
          this.$store.commit("UPDATE_USER", this.enterprises[0]);
          this.$store.commit("UPDATE_LOGIN", true);

          //  select //
          if (this.enterprises[0].companyId) {
            this.selectParameterAll();
          }

          // UserAddress
          if (this.informUserAddress) {
            // snackbar
            // window.scrollTo(0, 0);
            this.snackbar.color = "success";
            this.snackbar.text =
              this.$t("address.Address saved: ") + this.address.address;
            this.snackbarTimeout(2000);
            return;
          }
          //
          this.$router.push({ name: "menu" });
        })
        .catch((err) => {
          // console.log(err);
          this.btnEnterDisabled = false;
          // snackbar
          err = err.data ? err.data : err;
          // console.log("err.response : " + JSON.stringify(err.response));
          if (err.response == undefined) {
            window.scrollTo(0, 0);
            this.snackbar.color = "error";
            this.snackbar.text = err + " => loginUser";
            this.snackbar.show = true;
          } else {
            if (err.response.status == 404) {
              window.scrollTo(0, 0);
              this.snackbar.color = "warning";
              this.snackbar.text = this.$t(
                "login.Invalid email or password(s)"
              );
              this.snackbar.show = true;
              //
            } else {
              if (err.response.status == 401) {
                window.scrollTo(0, 0);
                this.snackbar.color = "warning";
                this.snackbar.text = this.$t("login." + err.response.data);
                this.snackbar.show = true;
              } else {
                if (err.response.status == 409) {
                  // Conflit version
                  window.scrollTo(0, 0);
                  this.snackbar.color = "warning";
                  this.snackbar.text = this.$t("login." + err.response.data);
                  this.snackbar.show = true;
                } else {
                  window.scrollTo(0, 0);
                  this.snackbar.color = "error";
                  this.snackbar.text = err + " => loginUser";
                  this.snackbar.show = true;
                }
              }
            }
          }
        });
    },

    // Update //
    logoutUser() {
      api
        .put(`/login/logout/?sessionId=${this.$store.state.user.sessionId}`)
        .then((response) => {
          // console.log(response.data);
        })
        .catch((err) => {
          // snackbar
          window.scrollTo(0, 0);
          this.snackbar.color = "error";
          this.snackbar.text = err + " => logout";
          this.snackbar.show = true;
          //
        });
    },

    //  selectParameterAll //
    selectParameterAll() {
      api
        .get(`/parameter/`)
        .then((response) => {
          this.$store.commit("UPDATE_PARAMETER", response.data);
          // console.log(JSON.stringify(this.$store.state.parameter));
        })
        .catch((err) => {
          // snackbar
          window.scrollTo(0, 0);
          this.snackbar.color = "error";
          this.snackbar.text = err + " => selectParameterAll";
          this.snackbar.show = true;
          //
        });
    },
  },

  //
  created() {
    document.title = "BLUE21";

    // console.log("pageOrigin = " + sessionStorage.pageOrigin);
    if (sessionStorage.pageOrigin != "footer") {
      sessionStorage.clear();
    }

    if (localStorage.language) {
      this.indexLanguage = this.languages.findIndex(
        (e, index, array) => e.language === localStorage.language
      );
      const locale = this.languages.find(
        (e, index, array) => e.language === localStorage.language
      );
      // console.log(locale);
      this.changeLocale(locale);
    } else {
      this.changeLocale({
        flag: "br",
        locale: "pt-br",
        language: "Portuguese Brazil",
      });
      // this.showLanguage = true;
    }
    // console.log("this.indexLanguage = " + this.indexLanguage);

    // route footer logout //
    // if (this.$route.params.option === "logout") {
    if (sessionStorage.pageOrigin === "footer") {
      this.logoutUser();
      document.location.reload(false); // page reload whith cache
      sessionStorage.clear();
    }

    // if (this.$route.params.pageOrigin) {
    //   this.pageOrigin = this.$route.params.pageOrigin;
    //   this.groupIdOrigin = this.$route.params.groupIdOrigin;
    //   this.companyIdOrigin = this.$route.params.companyIdOrigin;
    // }
    if (sessionStorage.pageOrigin) {
      this.pageOrigin = sessionStorage.pageOrigin;
      this.groupIdOrigin = sessionStorage.groupIdOrigin;
      this.companyIdOrigin = sessionStorage.companyIdOrigin;
    }

    sessionStorage.entity = "login";
    // Clear Vuex
    this.$store.replaceState({
      login: false,
      user: {
        userId: "",
      },
      parameter: {},
    });
    this.tradingName = sessionStorage.tradingName || "";

    // clear localStorage --> version  /////////////////////////////////
    // console.log("localStorage.version = " + localStorage.version);

    if (
      !localStorage.version ||
      localStorage.version.substring(0, 5) !=
        packageJson.version.substring(0, 5)
    ) {
      let locale = localStorage.locale;
      let language = localStorage.language;
      //
      localStorage.clear();
      //
      localStorage.locale = locale;
      localStorage.language = language;
    }
    localStorage.version = packageJson.version;
    //
    this.counterAccount = localStorage.counterAccount;
    // get IP
    this.getUserInfo();
    this.userInfo.appVersion = packageJson.version;

    this.environment = window.location.href.toLowerCase();
  },
};
</script>

