<template>
  <div>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- menu superior -->
    <!-- blue darken-2 = primary -->
    <v-app-bar
      :color="appBarColor"
      density="compact"
      class="mb-3"
    >
      <!-- @click="$router.back()" -->
      <template v-slot:prepend>
        <v-btn
          v-if="$store.state.login ||  $store.state.newAccount || $route.meta.title.match(/Forgot/)"
          icon
          @click="$router.go(-1)"
        >
          <v-icon size="24px">mdi-chevron-left</v-icon>
        </v-btn>
      </template>

      <!-- {{ $route.meta.title }} - -->

      <!-- v-if="
          ($store.state.login ||
          $store.state.newAccount ||
          $route.meta.title.match(/Delivery/) ||
          $route.meta.title.match(/Forgot/)) &&
          $route.meta.title != 'Menu '
        " -->

      <v-app-bar-title
        class="text-no-wrap"
        :style="{ 'font-family': 'LokkoSt', 'font-size': '14px'}"
      >BLUE21</v-app-bar-title>
      <v-app-bar-title class="text-no-wrap">
        {{ $store.state.user.tradingName }}
      </v-app-bar-title>
      <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
      <!-- menu à direita -->
      <v-spacer></v-spacer>
      <div v-if="($store.state.enterpriseCounter || $store.state.user.entity == 'supplier' || $store.state.user.entity == 'customer') &&
              ($route.meta.title.match(/Edit/) ||
                $route.meta.title.match(/New/) ||
                $route.meta.title.match(/Address/) ||
                $route.meta.title.match(/Contact/) ||
                $route.meta.title.match(/Bank/) ||
                $route.meta.title.match(/1_access/))">
        <!-- Recent-->
        <v-tooltip
          location="bottom"
          :text="$t('buttom.Recents')"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="text"
              v-if="$store.state.user.profile != 'Self-Service'"
              icon
              @click="$emit(navbar.recent)"
            >
              <v-icon>mdi-history</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </div>

      <!-- profile == 'Deliver'  -->
      <div v-if="$store.state.user.profile == 'Deliver' && !$route.meta.title.match(/User/) && ($route.meta.title.match(/Delivery/) || $route.meta.title.match(/Edit/) || $route.meta.title.match(/New/))">
        <!-- Recent Delivery-->
        <v-tooltip
          location="bottom"
          :text="$t('buttom.My Orders')"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              variant="text"
              @click="$emit(navbar.recent)"
            >
              <v-icon>mdi-history</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </div>

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn
            icon="mdi-dots-vertical"
            v-bind="props"
          ></v-btn>
        </template>

        <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
        <!-- menu a dots -->
        <v-list>
          <v-list-subheader>
            <div v-if="$store.state.user.profile != 'Deliver' && !$route.query.delivery">
              {{ $t("common." + $route.meta.title) }}
            </div>
            <div v-else>
              {{ $t("common.deliver" ) }}
            </div>
          </v-list-subheader>
          <div>
            <v-list-item
              v-for="(item, i) in navbar.menu"
              :key="i"
              @click="$emit(item.action)"
              router
              :to="item.route"
            >
              <!-- <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon> -->
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>
              <!-- <div> -->
              <!-- <v-list-item-title v-text="item.text"></v-list-item-title> -->
              <v-list-item-title v-text="$t('menu.' + item.text)"></v-list-item-title>
              <!-- </div> -->
            </v-list-item>
          </div>
        </v-list>
      </v-menu>
      <!-- parametrizar -->
      <!-- https://stackoverflow.com/questions/49721710/how-to-use-vuetify-tabs-with-vue-router -->
      <!-- <v-tab v-for="tab in tabs" :key="tab.id" :to="tab.route" exact> -->

      <template
        v-slot:extension
        v-if="
          ($route.meta.title.match(/Edit User/)  && $store.state.user.profile != 'Deliver') ||
          $route.meta.title.match(/User Access/) ||
          $route.meta.title.match(/User Logins/) ||
          $route.meta.title == 'Edit Customer' ||
          $route.meta.title == 'Customer Commercial' ||
          $route.meta.title == 'Edit Enterprise' ||
          $route.meta.title == 'Enterprise Consumer' ||
          $route.meta.title == 'Edit Product' ||
          $route.meta.title == 'Product - Consumer Sales' ||
          $route.meta.title.match(/Layout/) ||
          $route.meta.title.match(/Imprint/)"
      >
        <router-view>
          <v-tabs
            align-tabs="title"
            v-if="$route.meta.title.match(/User/)"
          >
            <v-tab to="/user">{{ $t("menu.User") }}</v-tab>
            <v-tab to="/access">{{ $t("menu.Accesses") }}</v-tab>
            <v-tab to="/logins">{{ $t("menu.Logins") }}</v-tab>
          </v-tabs>
          <v-tabs
            align-tabs="title"
            v-if="$route.meta.title == 'Edit Customer' || $route.meta.title == 'Customer Commercial'"
          >
            <v-tab to="/customer">{{ $t("common.Customer") }}</v-tab>
            <v-tab to="/customer_commercial">{{ $t("common.Commercial") }}</v-tab>
          </v-tabs>

          <v-tabs
            align-tabs="title"
            v-if="$route.meta.title == 'Edit Enterprise' || $route.meta.title == 'Enterprise Consumer'"
          >
            <v-tab to="/enterprise">{{ $t("common.Enterprise") }}</v-tab>
            <v-tab to="/enterprise_module">{{ $t("common.Modules") }}</v-tab>
            <v-tab
              v-if="$store.state.enterprises"
              to="/enterprise_share"
            >{{ $t("common.Sharing") }}</v-tab>
          </v-tabs>

          <v-tabs
            align-tabs="title"
            v-if="$store.state.user.consumerModule  && ($store.state.user.consumerDelivery || $store.state.user.consumerSelf) && $route.meta.title == 'Edit Product' || $route.meta.title == 'Product - Consumer Sales'"
          >
            <v-tab to="/product">{{ $t("common.Product") }}</v-tab>
            <v-tab to="/product_consumer">{{ $t("common.Consumer") }}</v-tab>
          </v-tabs>

          <v-tabs
            align-tabs="title"
            v-if="$route.meta.title.match(/Layout/)"
          >
            <v-tab to="/layout_model">{{ $t("common.Layout") }}</v-tab>
            <v-tab to="/layout_imprint">{{ $t("common.Print") }}</v-tab>
          </v-tabs>
        </router-view>
      </template>
    </v-app-bar>

    <!-- btnGoTop -->
    <!-- size="large" -->

    <v-btn
      v-scroll="onScroll"
      v-show="btnGoTop"
      position="fixed"
      location="bottom right"
      class="me-2 mb-16"
      icon="mdi-chevron-up"
      color="primary"
      style="opacity: 0.5; z-index: 99;"
      @click="toTop()"
    >
    </v-btn>

  </div>
  <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
</template>

<script>
var packageJson = require("../../../../../package.json");

export default {
  name: "Navbar",
  props: {
    navbar: {},
  },

  //////////////
  data() {
    return {
      windowWidth: 0,
      appBarColor: "",
      btnGoTop: false,
    };
  },

  /////////
  methods: {
    /////////////
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      // console.log("top ", top);
      this.btnGoTop = top > 400;
    },

    /////////
    toTop() {
      window.scrollTo(0, 0);
    },
  },

  /////////
  created() {
    this.windowWidth = window.innerWidth;
    // alert(" windowWidth = " + this.windowWidth);
    this.appBarColor = packageJson.app_bar_color;

    // console.log("groupId:", this.$store.state.user.groupId);
    if (window.location.href.toLowerCase().includes("localhost")) {
      this.appBarColor = "teal";
    } else if (
      window.location.href.toLowerCase().includes("teste.blue21.com.br")
    ) {
      this.appBarColor = "blue-grey-darken-1";
    } else if (this.$store.state.user.groupId == 53) {
      this.appBarColor = "purple";
    }
  },
};
</script>
