import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router.js'
import store from './store/store.js'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import FlagIcon from "vue-flag-icon"; // used in i18n
import { VInlineTextField } from '@wdns/vuetify-inline-fields';

// component
import LoadingPage from '@/app/2_common/components/page/LoadingPage.vue'

// i18n ////////////////////////////////////////////////////////////////
import { createI18n } from 'vue-i18n'
import pt from "./locales/pt-br.json"
import en from "./locales/en-us.json"
const i18n = createI18n({
  locale: navigator.language,
  fallbackLocale: "en",
  // fallbackLocale: "pt",
  messages: { pt, en },
  legacy: false
})
/////////////////////////////////////////////////////////////////////////

loadFonts()

// Vue.config.productionTip = false;

const app = createApp(App)
app.use(router).use(i18n).use(store).use(vuetify).use(FlagIcon)

router.isReady().then(() => {
  app.mount('#app')
})

app.component('LoadingPage', LoadingPage)
app.component('VInlineTextField', VInlineTextField);